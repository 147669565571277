import React from 'react'
import chunk from 'lodash.chunk'
import { Box, Row, Column } from '../components/ui'

export const padArrayChunks = (groups, len) => {
  return groups.map(chunk => {
    if (chunk.length >= len) return chunk
    return new Array(len).fill(null).map((_, index) => (chunk[index] ? chunk[index] : null))
  })
}

export const CoverageCities = () => (
  <Row maxWidth="1240px" justifyContent="space-evenly" mx="auto" fontSize={20}>
    {padArrayChunks(
      chunk(
        ['West Fargo', 'South Fargo', 'Mapleton', 'N. Moorhead', "Reile's Acres", 'Kragnes '],
        3
      ),
      3
    ).map((group, index) => (
      <Column key={index}>
        {group.map(city => (
          <Box key={city} mb={3}>
            {city}
          </Box>
        ))}
      </Column>
    ))}
  </Row>
)
