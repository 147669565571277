import React from 'react'
import { Link } from 'gatsby'
import { Box, Row, Column } from './ui'
import logoOoma from '../images/services/ooma-telo.jpg'

import huluLogo from '../images/services/hulu.png'
import youtubeTvLogo from '../images/services/youtube-tv.png'
import netflixLogo from '../images/services/netflix.png'
import directvNowLogo from '../images/services/directv-now.png'
import slingLogo from '../images/services/sling.png'

// const CARD_HEIGHT = 450
const CARD_HEIGHT = 390

export const InternetPricing = () => {
  return (
    <Column
      className="pricing-card"
      bg="#fff"
      width={[250, 220, 210]}
      height={CARD_HEIGHT}
      borderRadius={10}
      css={{ boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.12)' }}
    >
      <Box
        fontSize={16}
        fontWeight="bold"
        textAlign="center"
        py={2}
        borderBottom="1px solid #eee"
        css={{ textTransform: 'uppercase' }}
      >
        FM / Reile's Acres
      </Box>

      <Column textAlign="center" height={80} py={12} borderBottom="1px solid #eee">
        <Box fontSize={12} fontWeight={600}>
          starting at
        </Box>
        <Box fontSize={32} fontWeight="bold">
          $35/mo
        </Box>
      </Column>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>
          <strong>Budget</strong> <span css={{ fontSize: 11 }}>Up to 40Mbps</span>
        </Box>
        <Box fontWeight="bold">$35</Box>
      </Row>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>
          <strong>Basic</strong> <span css={{ fontSize: 11 }}>Up to 100Mbps</span>
        </Box>
        <Box fontWeight="bold">$50</Box>
      </Row>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>
          <strong>Extreme</strong> <span css={{ fontSize: 11 }}>Up to 1Gb/s</span>
        </Box>
        <Box fontWeight="bold">$100</Box>
      </Row>

      <Box flex={1}></Box>

      <Column flex={1} fontSize={13}>
        <Row justifyContent="center" fontWeight="bold" pt={2}>
          Add Ons
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pt={2} pb={2}>
          <Box>Managed WiFi</Box>
          <Box fontWeight="bold">$5</Box>
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pb={2}>
          <Box>Dual ISP Router</Box>
          <Box fontWeight="bold">$20</Box>
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pb={2}>
          <Box>VoIP</Box>
          <Box fontWeight="bold">$25</Box>
        </Row>
      </Column>

      <Row
        justifyContent="center"
        alignItems="center"
        fontSize={14}
        fontWeight={600}
        bg="#2fb7f1"
        color="#fff"
        height={36}
        css={{
          textTransform: 'uppercase',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Link to="/contact">Sign Up Now</Link>
      </Row>
    </Column>
  )
}

export const InternetRuralPricing = () => {
  return (
    <Column
      className="pricing-card"
      bg="#fff"
      width={[250, 250, 210]}
      height={CARD_HEIGHT}
      borderRadius={10}
      css={{ boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.12)' }}
    >
      <Box
        fontSize={16}
        fontWeight="bold"
        textAlign="center"
        py={2}
        borderBottom="1px solid #eee"
        css={{ textTransform: 'uppercase' }}
      >
        Rural
      </Box>

      <Column textAlign="center" height={80} py={12} borderBottom="1px solid #eee">
        <Box fontSize={12} fontWeight={600}>
          starting at
        </Box>
        <Box fontSize={32} fontWeight="bold">
          $40/mo
        </Box>
      </Column>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>Up to 50Mbps</Box>
        <Box fontWeight="bold">$40</Box>
      </Row>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>Up to 100Mbps</Box>
        <Box fontWeight="bold">$65</Box>
      </Row>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>100Mbps +</Box>
        <Box fontWeight="bold">$80</Box>
      </Row>

      <Box flex={1}></Box>

      <Column flex={1} fontSize={13}>
        <Row justifyContent="center" fontWeight="bold" pt={2}>
          Add Ons
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pt={2} pb={2}>
          <Box>Managed WiFi</Box>
          <Box fontWeight="bold">$5</Box>
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pb={2}>
          <Box>Dual ISP Router</Box>
          <Box fontWeight="bold">$20</Box>
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pb={2}>
          <Box>VoIP</Box>
          <Box fontWeight="bold">$25</Box>
        </Row>
      </Column>

      <Row
        justifyContent="center"
        alignItems="center"
        fontSize={14}
        fontWeight={600}
        bg="#2fb7f1"
        color="#fff"
        height={36}
        css={{
          textTransform: 'uppercase',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Link to="/contact">Sign Up Now</Link>
      </Row>
    </Column>
  )
}

export const InternetBusinessPricing = () => {
  return (
    <Column
      className="pricing-card"
      bg="#fff"
      width={[250, 250, 210]}
      height={CARD_HEIGHT}
      borderRadius={10}
      css={{ boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.12)' }}
    >
      <Box
        fontSize={16}
        fontWeight="bold"
        textAlign="center"
        py={2}
        borderBottom="1px solid #eee"
        css={{ textTransform: 'uppercase' }}
      >
        Business
      </Box>

      <Column textAlign="center" height={80} py={12} borderBottom="1px solid #eee">
        <Box fontSize={12} fontWeight={600}>
          starting at
        </Box>
        <Box fontSize={32} fontWeight="bold">
          $75/mo
        </Box>
      </Column>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>Up to 50Mbps</Box>
        <Box fontWeight="bold">$75</Box>
      </Row>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>Up to 100Mbps</Box>
        <Box fontWeight="bold">$100</Box>
      </Row>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>Up to 200Mbps</Box>
        <Box fontWeight="bold">$200</Box>
      </Row>

      <Row
        justifyContent="space-between"
        alignItems="center"
        px={3}
        py={2}
        fontSize={13}
        borderBottom="1px solid #eee"
      >
        <Box>Up to 1Gbps</Box>
        <Box fontWeight="bold">CALL</Box>
      </Row>

      {/*
      <Column flex={1}>
        <Column px={3} py={2} fontSize={13}>
          <Box>Dual ISP Router w/ Failover</Box>
          <Box fontWeight="bold">$25/mo</Box>
        </Column>
      </Column> */}

      <Column flex={1} fontSize={13}>
        <Row justifyContent="center" fontWeight="bold" pt={2}>
          Add Ons
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pt={2} pb={2}>
          <Box>Managed WiFi</Box>
          <Box fontWeight="bold">$5</Box>
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pb={2}>
          <Box>Dual ISP Router</Box>
          <Box fontWeight="bold">$20</Box>
        </Row>
        <Row justifyContent="space-between" alignItems="center" px={3} pb={2}>
          <Box>VoIP</Box>
          <Box fontWeight="bold">$25</Box>
        </Row>
      </Column>

      <Row
        justifyContent="center"
        alignItems="center"
        fontSize={14}
        fontWeight={600}
        bg="#2fb7f1"
        color="#fff"
        height={36}
        css={{
          textTransform: 'uppercase',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Link to="/contact">Sign Up Now</Link>
      </Row>
    </Column>
  )
}

export const PhonePricing = () => {
  return (
    <Column
      className="pricing-card"
      bg="#fff"
      width={[250, 250, 210]}
      height={CARD_HEIGHT}
      borderRadius={10}
      css={{ boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.12)' }}
    >
      <Box
        fontSize={16}
        fontWeight="bold"
        textAlign="center"
        py={2}
        borderBottom="1px solid #eee"
        css={{ textTransform: 'uppercase' }}
      >
        Phone
      </Box>

      <Column
        justifyContent="center"
        textAlign="center"
        py={12}
        height={80}
        borderBottom="1px solid #eee"
      >
        <Box fontSize={32} fontWeight="bold">
          FREE
        </Box>
      </Column>

      <Box px={3} py={2} fontSize={13}>
        Ooma Telo&nbsp;-&nbsp;
        <span css={{ fontWeight: 'bold' }}>$0</span>&nbsp;+&nbsp;taxes/fees
      </Box>

      <Column flex={1}>
        <Row justifyContent="center" py={3}>
          <img src={logoOoma} alt="Ooma Telo Service" css={{ width: 175 }} />
        </Row>
      </Column>

      <Row
        justifyContent="center"
        alignItems="center"
        fontSize={14}
        fontWeight={600}
        bg="#2fb7f1"
        color="#fff"
        height={36}
        css={{
          textTransform: 'uppercase',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Link to="/contact">Sign Up Now</Link>
      </Row>
    </Column>
  )
}

export const TelevisionPricing = () => {
  return (
    <Column
      className="pricing-card"
      bg="#fff"
      width={[250, 250, 210]}
      height={CARD_HEIGHT}
      borderRadius={10}
      css={{ boxShadow: '0px 6px 14px rgba(0, 0, 0, 0.12)' }}
    >
      <Box
        fontSize={16}
        fontWeight="bold"
        textAlign="center"
        py={2}
        borderBottom="1px solid #eee"
        css={{ textTransform: 'uppercase' }}
      >
        Television
      </Box>

      <Column textAlign="center" height={80} py={12} borderBottom="1px solid #eee">
        <Box fontSize={12} fontWeight={600}>
          starting at
        </Box>
        <Box fontSize={32} fontWeight="bold">
          $8.99/mo
        </Box>
      </Column>

      <Row justifyContent="space-between" px={3} py={2} fontSize={13} borderBottom="1px solid #eee">
        <Box>Hulu Live TV</Box>
        <Box fontWeight="bold">$44.99</Box>
      </Row>

      <Row justifyContent="space-between" px={3} py={2} fontSize={13} borderBottom="1px solid #eee">
        <Box>DirecTV Now</Box>
        <Box fontWeight="bold">$50 and up</Box>
      </Row>

      <Row justifyContent="space-between" px={3} py={2} fontSize={13} borderBottom="1px solid #eee">
        <Box>YouTube TV</Box>
        <Box fontWeight="bold">$49.99</Box>
      </Row>

      <Row justifyContent="space-between" px={3} py={2} fontSize={13} borderBottom="1px solid #eee">
        <Box>Netflix</Box>
        <Box fontWeight="bold">$8.99 and up</Box>
      </Row>

      <Row justifyContent="space-between" px={3} py={2} fontSize={13} borderBottom="1px solid #eee">
        <Box>Sling TV</Box>
        <Box fontWeight="bold">$15 and up</Box>
      </Row>

      <Column flex={1} px={3}>
        <Row justifyContent="center" alignItems="center" mt={2} height={40}>
          <Box>
            <img src={huluLogo} alt="Ooma Telo Service" css={{ width: 80 }} />
          </Box>

          <Box>
            <img src={youtubeTvLogo} alt="Ooma Telo Service" css={{ width: 120 }} />
          </Box>
        </Row>

        <Row justifyContent="center" alignItems="center" height={50}>
          <Box pr={2}>
            <img src={netflixLogo} alt="Ooma Telo Service" css={{ width: 100 }} />
          </Box>

          <Box pr={2}>
            <img src={directvNowLogo} alt="Ooma Telo Service" css={{ width: 60 }} />
          </Box>
        </Row>

        <Row justifyContent="center">
          <img src={slingLogo} alt="Sling TV Service" css={{ width: 60 }} />
        </Row>
      </Column>

      <Row
        justifyContent="center"
        alignItems="center"
        fontSize={14}
        fontWeight={600}
        bg="#2fb7f1"
        color="#fff"
        height={36}
        css={{
          textTransform: 'uppercase',
          borderBottomLeftRadius: 10,
          borderBottomRightRadius: 10,
        }}
      >
        <Link to="/contact">Sign Up Now</Link>
      </Row>
    </Column>
  )
}
